import React, { useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

// import Search from '../../components/settlements/Search';
import Summary from '../../components/settlements/Summary';
import Awaiting from './settlements/Awaiting';
import Reports from './settlements/Reports';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import Loading from '../../../main/components/loading';
import useSpaceBillings from '../../hooks/useSpaceBillings';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import { useSelector } from 'react-redux';
import Breadcrumbs  from './Breadcrumbs';

const SpaceSettlements = ({ match }) => {
  const { t } = useTranslation();
  const { space } = useCurrentSpace(match);
  
  const dataLocations = useMemo(() => ([
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('nav_items.settlements') }
  ]), [ space?.name, space?.id, t ]);

  const selectDates = state => ({
    services: state.services.servicesList.get('data'),
    isLoadingServices: state.services.servicesList.get('isLoading'),
  });

  const { services, isLoadingServices } = useSelector(selectDates);

  const { data: billings, isLoading: billingsIsLoading } =
    useSpaceBillings(services);

  if (!space || isLoadingServices || billingsIsLoading) {
    return (
      <>
        <Breadcrumbs  dataLocations={dataLocations} />
        <Loading />
      </>
    );
  }

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocations} />
      <Helmet>
        <title>
          {t("page_titles.spaces.settlements", { space: space.name })}
        </title>
      </Helmet>
      <Card className="p-4 mt-5">
        {/* <Search /> */}
        <Summary servicesLen={services.length} settlements={billings} />
        <Awaiting settlements={billings} />
        <Reports settlements={billings} />
      </Card>
    </>
  );
};

SpaceSettlements.propTypes = {
  match: PropTypes.object.isRequired, // HOC
};

export default compose(
  ErrorBoundary((props) => props.t("settlements.error_boundary"))
)(SpaceSettlements);