import { Map } from 'immutable';
import { combineReducers } from 'redux';

import {
  FETCH_SPACE_LIST_REQUEST,
  FETCH_SPACE_LIST_ERROR,
  FETCH_SPACE_LIST_SUCCESS,
  FETCH_OWN_ROLES_ERROR,
  FETCH_OWN_ROLES_REQUEST,
  FETCH_OWN_ROLES_SUCCESS,
  FETCH_SPACE_IMG_REQUEST,
  FETCH_SPACE_IMG_ERROR,
  FETCH_SPACE_IMG_SUCCESS,
} from '../actions/spacesActions';

const initialState = Map({
  isLoading: false,
  data: [],
  error: null,
});

const initialStateForRoles = Map({
  isLoading: true,
  data: [],
  error: null,
});

const spaceListReducer = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_SPACE_LIST_REQUEST:
    return state.set("isLoading", true);
  case FETCH_SPACE_LIST_SUCCESS:
    return state.set("isLoading", false).set("data", action.payload);
  case FETCH_SPACE_LIST_ERROR:
    return state.set("isLoading", false).set("error", action.payload);
  default:
    return state;
  }
};

const spaceImgReducer = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_SPACE_IMG_REQUEST:
    return state.set("isLoading", true);
  case FETCH_SPACE_IMG_SUCCESS:
    return state.set("isLoading", false).set("data", action.payload);
  case FETCH_SPACE_IMG_ERROR:
    return state.set("isLoading", false).set("error", action.payload);
  default:
    return state;
  }
};

const ownRolesReducer = (state = initialStateForRoles, action) => {
  switch (action.type) {
  case FETCH_OWN_ROLES_REQUEST:
    return state.set("isLoading", true);
  case FETCH_OWN_ROLES_SUCCESS:
    return state.set("isLoading", false).set("data", action.payload);
  case FETCH_OWN_ROLES_ERROR:
    return state.set("isLoading", false).set("error", action.payload);
  default:
    return state;
  }
};

export const spacesReducers = combineReducers({
  spaceList: spaceListReducer,
  ownRoles: ownRolesReducer,
  spaceImg: spaceImgReducer,
});
