import _ from 'lodash';
import { checkLanguage } from './componentsLocal';

const backendTranslation = (field, ifEmptyValue = '') => {
  const lang = checkLanguage();
  return (field && !_.isEmpty(field))
    ? (field.hasOwnProperty(lang)? field[lang] : field)
    : ifEmptyValue;
};

export default backendTranslation;