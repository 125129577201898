import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loading from '../../../main/components/loading';
import { multiLangType } from '../../types';
import backendTranslation from '../../utils/backendTranslation';
import { Td } from '../../styles/styled-components/Affiliations';
import { OverDueDate } from '../../styles/styled-components/Settlements';
import { SquareButton } from '../../styles/styled-components/Button';
import { ReactComponent as PlusIcon } from '../../../assets/svg/common/plus-3.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/common/edit.svg';
import useAxios from '../../hooks/useAxios';
import RaportModal from './RaportModal';
import { __env } from '../../../envloader';
import Badge, { billingStatuses, settlementStates } from './Badge';
import { ReactComponent as RejectedIcon } from '../../../assets/svg/spaces/rejected.svg';

const SettlementsRow = ({
  settlement: {
    id,
    name,
    serviceId,
    serviceName,
    state,
    creation_date,
    due_date,
    billing,
  },
}) => {
  const match = useRouteMatch({
    path: "/spaces/:id",
  });
  const { t, i18n } = useTranslation();
  const [ modal, setModal ] = useState(null);

  const silent = useMemo(
    () => ({
      __silentFor: [ { status: 404 } ],
    }),
    []
  );

  const {
    isLoading,
    data,
    refetch: refetchIbis,
  } = useAxios(
    `
    ${__env.IBIS_TENANT_URL}spaces/${match.params.id}/services/${serviceId}/billing_reports/billing_report_${id}.json
  `,
    {
      otherOptions: silent,
    }
  );

  const toggle = () => setModal(!modal);
  return (
    <>
      <tr className="font-sm" key={id}>
        <Td>{creation_date}</Td>
        <Td className="fontWeight-bold text-secondary">
          <Link to={`./settlements/${serviceId}/${id}`}>
            {backendTranslation(name)}
          </Link>
        </Td>
        <Td className="fontWeight-bold text-secondary">{serviceName}</Td>
        <Td className="fontWeight-bold">
          {billing?.report ? t("reports.attached") : t("reports.empty")}
        </Td>
        <Td>
          {state === settlementStates.PREPARED &&
          new Date(due_date) < new Date() ? (
              <OverDueDate>{due_date}</OverDueDate>
            ) : (
              due_date
            )}
        </Td>
        <Td>
          {state === settlementStates.PREPARED && isLoading ? (
            <Loading />
          ) : state === settlementStates.PAID ||
            billing?.status === billingStatuses.ACTIVE ||
            billing?.status === billingStatuses.IN_REVIEW ||
            (billing?.status === billingStatuses.DENIED &&
              !(billing?.refusal_comment || billing?.refusal_reason)) ? (
              <Badge state={state} billingStatus={billing?.status} />
            ) : (
              <SquareButton onClick={toggle}>
                {billing?.status === billingStatuses.DENIED ? (
                  <RejectedIcon className="mr-2" />
                ) : state === settlementStates.PREPARED && !data ? (
                  <PlusIcon className="mr-2" />
                ) : (
                  <EditIcon className="mr-2" />
                )}
                {billing?.status === billingStatuses.DENIED
                  ? t("reports.fix_report")
                  : data
                    ? t("reports.edit_report")
                    : t("reports.add_report")}
              </SquareButton>
            )}
        </Td>
      </tr>
      {!isLoading && (
        <RaportModal
          modal={modal}
          toggle={toggle}
          serviceId={serviceId}
          initialData={billing?.report ?? data ?? undefined}
          feedback={
            billing?.refusal_comment || billing?.refusal_reason
              ? {
                refusal_comment: billing?.refusal_comment?.[i18n.resolvedLanguage],
                refusal_reason: billing?.refusal_reason?.[i18n.resolvedLanguage],
              }
              : null
          }
          refetchIbis={refetchIbis}
          billingId={id}
        />
      )}
    </>
  );
};

SettlementsRow.propTypes = {
  settlement: PropTypes.shape({
    id: PropTypes.number,
    files: PropTypes.string,
    name: multiLangType,
    serviceId: PropTypes.number,
    serviceName: PropTypes.string,
    state: PropTypes.string,
    creation_date: PropTypes.string,
    due_date: PropTypes.string,
    billing: PropTypes.shape({
      refusal_comment: PropTypes.string,
      refusal_reason: PropTypes.string,
      status: PropTypes.string,
      report: PropTypes.object,
    }),
  }),
};

export default SettlementsRow;
