import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { multiLangType } from '../../types';
import { ReactComponent as ServiceIcon } from '../../../assets/svg/spaces/service.svg';

const Summary = ({ servicesLen, settlements }) => {
  const { t } = useTranslation();

  const settlementsWithProperState = settlements.filter(
    (s) => s.state === "PREPARED"
  );

  const numberOfServices = servicesLen;
  const reportsAfterDueDate = settlementsWithProperState.filter(
    (s) => new Date(s.due_date) < new Date()
  ).length;
  const reportsBeforeDueDate = settlementsWithProperState.filter(
    (s) => new Date(s.due_date) >= new Date()
  ).length;
  const numberOfAllReports = settlementsWithProperState.length;

  const chooseTranslation = (numberOf, itemType) => {
    let item;
    switch (itemType) {
    case 'report':
      item = { zero: "settlements.no_reports", single: "settlements.report", plural: "settlements.reports", many: "settlements.manyReports" };
      break;
    case 'service':
      item = { zero: "settlements.manyServices", single: "settlements.service", plural: "settlements.services", many: "settlements.manyServices" };
      break;
    default:
      throw new Error("Invalid item type");
    }

    switch (true) {
    case (numberOf === 0):
      return t(item.zero);
    case (numberOf === 1):
      return `${numberOf} ${t(item.single)}`;
    case ![ 12, 13, 14 ].includes(numberOf % 100) && [ 2, 3, 4 ].includes(numberOf % 10):
      return `${numberOf} ${t(item.plural)}`;
    default:
      return `${numberOf} ${t(item.many)}`;
    }
  };



  return (
    <>
      <Card className="my-2 bg-light rounded">
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="pr-5 text-muted">
                <span className="m-0 ml-3 font-sm fontWeight-bold">
                  {t("settlements.to_settle")}
                </span>
                <div className="d-flex">
                  <ServiceIcon className="mt-1" />
                  <h1 className="fontWeight-bold m-1 font-xl">
                    {chooseTranslation(numberOfServices, 'service')}
                  </h1>
                </div>
                <span className="m-0 ml-4 font-sm fontWeight-medium">
                  {chooseTranslation(numberOfAllReports, 'report')}
                </span>
              </div>
              <div className="px-5 text-danger">
                <p className="m-0 font-sm">
                  {t("settlements.after_due_date")}:
                </p>
                <span className="m-0 font-sm fontWeight-medium">
                  {chooseTranslation(reportsAfterDueDate, 'report')}
                </span>
                <span className="text-muted ml-1">
                  {reportsAfterDueDate.length === 1 &&
                    `(${reportsAfterDueDate[0].due_date})`}
                </span>
              </div>
              <div className="pl-5">
                <p className="m-0 font-sm">{t("settlements.current")}:</p>
                <span className="m-0 font-sm fontWeight-medium">
                  {chooseTranslation(reportsBeforeDueDate, 'report')}
                </span>
                <span className="text-muted ml-1">
                  {reportsBeforeDueDate.length === 1 &&
                    `(${reportsBeforeDueDate[0].due_date})`}
                </span>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

Summary.propTypes = {
  servicesLen: PropTypes.number,
  settlements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      files: PropTypes.string,
      serviceName: PropTypes.string,
      name: multiLangType,
      state: PropTypes.string,
      creation_date: PropTypes.string,
      due_date: PropTypes.string,
    })
  ),
};

export default Summary;
