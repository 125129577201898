import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Button, Alert } from 'reactstrap';
import { __env } from '../../envloader';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Loading from '../../main/components/loading';
import AffiliationsTable from '../components/AffiliationsTable';
import AffiliationsInProgress from './AffiliationsInProgress';
import { AffiliationsHeader } from '../styles/styled-components/Affiliations';
import axios from '../../main/utils/axios/axiosInstance';
import parseIfString from '../utils/objectUtils';
import { useCallback } from 'react';
import backendTranslation from './../utils/backendTranslation';

const Affiliations = ({ contractAffiliations = false, contractId, affiliationsNotFoundMessage, hiddenAddNew = false }) => {
  const [ affiliations, setAffiliations ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const { t } = useTranslation();

  const getAffiliations = useCallback(() => {
    let uri = '';
    if (contractAffiliations && contractId) {
      uri = `${__env.USERS_DATA_API_URL}api/users-data/contracts/${contractId}/affiliations/`;
    } 
    else if (!contractAffiliations) {
      uri = `${__env.USERS_DATA_API_URL}api/users-data/affiliations/`;
    }

    if (uri !== '') {
      return axios.get(uri)
        .then(({ data }) => {
          setAffiliations(() => {
            return data.items.map((item) => {
              const dataJson = parseIfString(item.data);
              return {
                id: item.id,
                user: item.user,
                updated: item.updated,
                expires: item.expires,
                affiliation_type: dataJson.affiliation_type,
                index_number: dataJson.index_number,
                affiliation_institution: backendTranslation(dataJson?.affiliation_institution)
              };
            });
          });
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err.response ? err.response.data : err.message);
          setIsLoading(false);
        });
    } 
    else {
      setIsLoading(false);
    }
  }, [ contractAffiliations, contractId ]);

  useEffect(() => {
    getAffiliations();
  }, [ getAffiliations ]);

  return (
    <>
      <Card>
        <div className='d-flex align-items-center justify-content-between p-4'>
          <AffiliationsHeader as={contractAffiliations ? 'h4' : 'h1'}>{t('affiliations.header')}</AffiliationsHeader>
          {
            !hiddenAddNew &&
              <Button tag={Link} to='/affiliations/new_affiliation'>{t('affiliations.add_new_affiliation')}</Button>
          }
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          (error) ? (
            <div className='px-2'>
              <Alert color='danger'>{t('affiliations.error')}</Alert>
            </div>
          ) : (
            affiliations.length === 0 ? (
              <div className='px-4 mb-3'>{affiliationsNotFoundMessage}</div>
            ) : (
              <AffiliationsTable affiliations={affiliations} refresh={getAffiliations} />
            )
          )
        )}
      </Card>
      {!contractAffiliations &&
        <AffiliationsInProgress/>
      }
    </>
  );
};

Affiliations.propTypes = {
  contractAffiliations: PropTypes.bool,
  contractId: PropTypes.string,
  affiliationsNotFoundMessage: PropTypes.string,
  hiddenAddNew: PropTypes.bool
};


export default Affiliations;