import React, { useCallback } from 'react';
import RJSFForm from '@rjsf/bootstrap-4';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import LinkWidget from './LinkWidget';
import SelectWidget from './SelectWidget';
import OTPWidget from './OTPWidget';
import LabelsWidget from './LabelsWidget';
import CheckboxWithLinkWidget from './CheckboxWithLinkWidget';

const Form = ({ resendButton, ...props }) => {
  const { t, i18n } = useTranslation();

  const transformErrors = useCallback((errors) => {
    return errors.map((error) => {
      let key = `form.errors.${error.name}`;
      const limit = error?.params?.limit;

      if (error.name === "type") {
        key += `.${error.params.type}`;
      }

      if (i18n.exists(key)) {
        error.message = t(key, { limit });
      }

      return error;
    });
  }, [ t, i18n ]);

  const widgets = {
    LinkWidget,
    SelectWidget,
    OTPWidget: (props) => <OTPWidget {...props} resendButton={resendButton} />,
    LabelsWidget,
    CheckboxWithLinkWidget
  };

  return (
    <RJSFForm 
      widgets={widgets}
      transformErrors={transformErrors}
      showErrorList={false}
      {...props} 
    />
  );
};

Form.propTypes = {
  resendButton: PropTypes.object
};

export default Form;