import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';

import backendTranslation from '../utils/backendTranslation';
import ServiceHistoryTable from './ServiceHistoryTable';
import { ProgressBar, Button, TextBold, TextBlueBold, TextUsage, TextLink, ProgressPoint, AngleDown } from '../styles/styled-components/ServiceUsageBar';
import { formatBasedOnValue } from '../utils/formatBasedOnValue';

const ServiceUsageBar = ({ data, type, history, eleNum = 0 }) => {

  const match = useRouteMatch();
  const { t } = useTranslation();

  const [ showHistory, setShowHistory ] = useState(false);

  const currentUsageData = data?.items[data?.items.length-1];
  const resource = currentUsageData?.resource_usage_data.resources.find(ele => ele.name === type);
  const name = backendTranslation(resource?.description);
  const unit = backendTranslation(resource?.unit);
  const max = resource?.limit ? resource.limit : -1;

  // Count used resources
  let used = 0.0;
  for (let i = 0; i < data?.items.length; i++) {
    used += data?.items[i]?.resource_usage_data?.resources?.find(ele => ele?.name === type)?.value;
  }

  used = formatBasedOnValue(used);

  const usedPercent = max >= 0 ? formatBasedOnValue(parseFloat(((used / max) * 100))) : 100;
  const orangeBar = max >= 0 && usedPercent >= 90 && usedPercent <= 100;
  const progressColor =
  max < 0 || orangeBar
    ? null
    : usedPercent >= 100
      ? "danger"
      : usedPercent >= 75
        ? "warning"
        : "primary";

  return (
    <div className="mt-4">
      <div className="d-flex align-items-center mb-2">
        <ProgressPoint className="mr-2" color={eleNum} />
        <TextBold className="mr-1">{name}:</TextBold>
        <TextBlueBold>{used}{' '}{unit}</TextBlueBold>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <ProgressBar value={usedPercent} color={progressColor} barStyle={{ backgroundColor: orangeBar ? '#FFA216' : '#106BEF' }} />
        {
          history &&
          <Button type="button" onClick={() => setShowHistory(!showHistory)}>
            <AngleDown reverse={showHistory ? 1 : 0} />
          </Button>
        }
      </div>
      <TextUsage>{t('services_details.used')} {used} {unit} {t('services_details.from')} {max >= 0 ? max : t('services_details.unlimited')} {unit}</TextUsage>
      {
        history &&
        <>
          <Link to={`/spaces/${match.params.id}/services/${match.params.service_id}/history/${type}`} className="d-flex justify-content-end">
            <TextLink>{t('services_details.to_history')} &gt;</TextLink>
          </Link>
          <Collapse isOpen={showHistory} >
            <ServiceHistoryTable data={data} type={type}/>
          </Collapse>
          <hr />
        </>
      }
    </div>
  );
};

ServiceUsageBar.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  history: PropTypes.bool,
  eleNum: PropTypes.number
};

export default ServiceUsageBar;