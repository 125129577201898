import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import Callback from './callback';
import LogoutCallback from './logoutCallback';
import { loginCallbackPath, logoutCallbackPath } from '../utils/authUtils';
import LoginToAccess from '../components/loginToAccess';
import NotFound from '../components/notFound';
import Loading from '../components/loading';
import { withTranslation } from 'react-i18next';

import '../../users-ui/styles/index.scss';
import TopBar from '../../users-ui/containers/topbar';
import SidebarContainer from '../../users-ui/containers/SidebarContainer';
import SpaceContainer from '../../users-ui/containers/spaces/SpaceContainer';
import SpaceList from '../../users-ui/containers/spaces/SpaceList';
import { MainContent, AppWrapper } from '../../users-ui/styles/styled-components/GlobalStyles';
import { getOwnRoles, getSpaceListWithUsers } from '../../users-ui/actions/spacesActions';
import ContractCategories from '../../users-ui/containers/ContractCategories';
import Notifications from '../../users-ui/containers/Notifications';
import Affiliations from '../../users-ui/containers/AffiliationsPage';
import SpaceProcessForm from '../../users-ui/containers/spaces/SpaceProcessForm';
import InvitationPrivate from '../../users-ui/components/invitations/private/PrivateInvitation';
import InvitationPublic from '../../users-ui/components/invitations/public/PublicInvitation';
import LandingPage from '../../users-ui/components/LandingPage';
import { toastr } from 'react-redux-toastr';
import Footer from '../../users-ui/containers/Footer';
import LowerCaseRoute from '../../users-ui/containers/routing/LowerCaseRoute ';
import Accessibility from '../../users-ui/components/Accessibility';
import * as notify from '../../main/utils/notify';

@withTranslation()
@connect(
  (state) => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
    location: state.router.location
  }),
  (dispatch) => {
    return bindActionCreators({
      getOwnRoles,
      getSpaceListWithUsers
    }, dispatch);
  }
)
export default class Root extends Component {

  componentDidUpdate(prevProps) {
    if (this.props.loginStore){
      this.props.getOwnRoles(true).then(() => {
        this.props.getSpaceListWithUsers();
      });
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      toastr.removeByType('error');
    }

    if (this.props.location.state?.fromSpaceNotFound) {
      notify.error('', this.props.t('space_not_found_message', { id: this.props.location.state?.spaceId }));
    }
  }

  renderContent = (location) => {
    const hideSidebar = location.pathname === '/accessibility';
    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading />;
    }

    if (!this.props.loginStore) {
      //User not logged in
      return (
        <Container fluid>
          <Switch>
            <LowerCaseRoute exact path='/accessibility' component={Accessibility}/>
            <Route exact strict path='/' component={LandingPage} />
            <Route exact path='/invitation/private/:id' component={InvitationPrivate} />
            <Route exact path='/invitation/public/:id' component={InvitationPublic} />
            <Route exact path='/new_contract' component={LoginToAccess} />
            <Route exact path='/notifications' component={LoginToAccess} />
            <Route path='/spaces/:id/' component={LoginToAccess} />
            <Route path='/process_form/:id/' component={LoginToAccess} />
            <Route path='/affiliations' component={LoginToAccess} />
            <Route path={loginCallbackPath} exact component={Callback} />
            <Route path={logoutCallbackPath} exact component={LogoutCallback} />
            <Route component={NotFound} />
          </Switch>
        </Container>
      );
    }
    else {
      //User logged in
      return (
        <AppWrapper hideSidebar={hideSidebar}>
          <SidebarContainer />
          <MainContent className='bg-light'>
            <Switch>
              <LowerCaseRoute exact path='/accessibility' component={Accessibility}/>
              <Route path={loginCallbackPath} exact render={() => <Redirect to='/'/>}/>
              <Route path={logoutCallbackPath} exact render={() => <Redirect to='/'/>}/>
              <Route exact strict path='/' component={SpaceList} />
              <Route exact path='/invitation/private/:id' component={InvitationPrivate}/>
              <Route exact path='/invitation/public/:id' component={InvitationPublic} />
              <Route exact path='/new_contract' component={ContractCategories} />
              <Route exact path='/notifications' component={Notifications} />
              <Route path='/spaces/:id/' component={SpaceContainer} />
              <Route path='/process_form/:id/' component={SpaceProcessForm} />
              <Route path='/affiliations' component={Affiliations} />
              <Route component={NotFound} />
            </Switch>
          </MainContent>
        </AppWrapper>
      );
    }
  };

  render() {
    const { location } = this.props;
    return (
      <>
        <TopBar />
        {this.renderContent(location)}
        <Footer />
      </>
    );
  }
}

Root.propTypes = {
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
  getOwnRoles: PropTypes.func, //HOC
  getSpaceListWithUsers: PropTypes.func, //HOC
  location: PropTypes.object,
  t: PropTypes.func
};
