import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { multiLangType } from '../../../types';
import SettlementsTable from '../../../components/settlements/SettlementsTable';

const Reports = ({ settlements }) => {
  const { t } = useTranslation();
  return (
    <>
      <h4 className="font-md m-3 text-capitalize">
        {t("settlements.reports")}
      </h4>
      <SettlementsTable
        settlements={settlements.filter((s) => s.state === "DONE")}
      />
    </>
  );
};

Reports.propTypes = {
  settlements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      files: PropTypes.string,
      serviceId: PropTypes.number,
      serviceName: PropTypes.string,
      name: multiLangType,
      state: PropTypes.string,
      creation_date: PropTypes.string,
      due_date: PropTypes.string,
    })
  ),
};

export default Reports;
