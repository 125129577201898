import React, { useState, useEffect, useMemo } from 'react';
import { Card, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import useAxios from '../hooks/useAxios';
import { __env } from '../../envloader';
import backendTranslation from '../utils/backendTranslation';
import { SelectYear, StraightLine, InputDate } from '../styles/styled-components/ServiceDetailsHistory';
import Loading from '../../main/components/loading';
import ServiceUsageBar from './ServiceUsageBar';
import ServiceHistoryTable from './ServiceHistoryTable';
import useCurrentSpace from '../hooks/useCurrentSpace';
import Breadcrumbs  from '../containers/spaces/Breadcrumbs';

const ServiceDetailsHistory = () => {

  const match = useRouteMatch();
  const { t } = useTranslation();
  const { space } = useCurrentSpace(match);

  const [ currentUsageData, setCurrentUsageData ] = useState();
  const [ filteredData, setFilteredData ] = useState(null);
  const [ mainChartData, setMainChartData ] = useState([]);
  const [ years, setYears ] = useState([]);
  const [ choosedYear, setChoosedYear ] = useState(new Date().getFullYear());
  const [ fromDate, setFromDate ] = useState();
  const [ fromDates, setFromDates ] = useState([ new Date(`01-01-${new Date().getFullYear()}`), new Date() ]);
  const [ toDate, setToDate ] = useState();
  const [ toDates, setToDates ] = useState([ new Date(`01-01-${new Date().getFullYear()}`), new Date() ]);
  
  const selectDates = state => ({
    services: state.services.servicesList.get('data')
  });

  const resourceType = match.params.resource;
  const serviceId = match.params.service_id;
  const spaceLocation = `/spaces/${space?.id}`;
  const { services } = useSelector(selectDates);
  const service = services?.filter(s => s.id === serviceId)[0];


  const resource = currentUsageData?.resource_usage_data.resources.find(ele => ele.name === resourceType);
  const processName = backendTranslation(resource?.description);

  const dataLocations = useMemo(() => ([
    { title: space?.name, location: spaceLocation },
    { title: t('nav_items.services'), location: `${spaceLocation}/services` },
    { title: service?.name, location: `${spaceLocation}/services/${serviceId}` },
    { title: `${t('history')} ${processName}` }
  ]), [ space?.name, spaceLocation, t, serviceId, service?.name, processName ]);

  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#0fa1ff'
    }
  });
  
  const styles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #777676',
      borderRadius: '6px'
    })
  };

  const { 
    data: usageData,
    isLoading: usageDataIsLoading
  } = useAxios(`${__env.SERVICE_DATA_API_URL}api/resources_usage/?service_id=${serviceId}`);

  const sortData = (a, b) => {
    let dateA = new Date(a.name.split(' - ')[0]);
    let dateB = new Date(b.name.split(' - ')[0]);
    return dateA - dateB;
  };

  useEffect(() => {
    if (usageData !== null) {
      setCurrentUsageData(usageData.items[usageData.items.length-1]);
      setFilteredData(usageData);
      
      // Generate years array
      const minDate = new Date(usageData.items[usageData.items.length - 1].date_from);
      const maxDate = new Date(usageData.items[0].date_to);
      let yearsArray = [];

      for (let i = maxDate.getFullYear(); i >= minDate.getFullYear(); i--) {
        yearsArray.push({
          value: i,
          label: i
        });
      };

      setYears(yearsArray);
    }
  }, [ usageData ]);
  
  useEffect(() => {
    if (fromDate) {
      let tempDates = [ ...toDates ];
      tempDates[0] = new Date(fromDate);
      setToDates(tempDates);
      if (toDate && fromDate.getTime() > toDate.getTime()) {
        setToDate(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ fromDate ]);

  useEffect(() => {
    setFromDate(null);
    setToDate(null);
    if (choosedYear !== new Date().getFullYear().toString()) {
      setFromDates([ new Date(`01-01-${choosedYear}`), new Date(`12-31-${choosedYear}`) ]);
      setToDates([ new Date(`01-01-${choosedYear}`), new Date(`12-31-${choosedYear}`) ]);
    } 
    else {
      setFromDates([ new Date(`01-01-${new Date().getFullYear()}`), new Date() ]);
      setToDates([ new Date(`01-01-${new Date().getFullYear()}`), new Date() ]);
    }
  }, [ choosedYear ]);

  const removeTime = date => new Date(date.toDateString());

  // Filter function
  useEffect(() => {
    if (usageData) {
      let tempData = _.cloneDeep(usageData);
      tempData.items = tempData.items.filter(item => {        
        const tempDataFrom = removeTime(new Date(item.date_from));
        const tempDataTo = removeTime(new Date(item.date_to));
        const startOfYear = new Date(choosedYear, 0, 1);
        const endOfYear = new Date(choosedYear, 11, 31);
        return tempDataFrom <= endOfYear 
          && tempDataTo >= startOfYear
          && (fromDate ? fromDate <= tempDataFrom : true)
          && (toDate ? toDate >= tempDataTo : true);
      });
      setFilteredData(tempData); 
    }
  }, [ usageData, choosedYear, fromDate, toDate ]);

  // Set main chart
  useEffect(() => {
    if (filteredData) {
      const transformedData = filteredData.items.map(ele => {
        const resource = ele.resource_usage_data.resources.find(r => r.name === resourceType);
        let tempReturn = {
          name: backendTranslation(ele?.period_name),
          ...resource && resource.unit.en && { [backendTranslation(resource.unit)]: Math.floor(resource.value) },
          date: `${ele?.date_from} - ${ele?.date_to}`
        };
        return tempReturn;
        
      });
  
      setMainChartData(transformedData.sort(sortData));
    }
  }, [ filteredData, resourceType ]);

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocations} />
      <Card className="p-4 mt-5">
        {
          usageDataIsLoading
            ? <Loading />
            : (
              usageData ? 
                <>
                  <h5>
                    {backendTranslation(currentUsageData?.resource_usage_data.resources.find(ele => ele.name === resourceType).description)}
                    {' '}
                    {t('services_details.details')}
                  </h5>
                  {
                    years[0] &&
                    <div className="d-flex align-items-center my-4" style={{ width: 'inherit' }}>
                      <SelectYear 
                        options={years} 
                        onChange={({ value }) => setChoosedYear(value)}
                        defaultValue={years[0]}
                        theme={theme}
                        styles={styles}
                      />
                      <StraightLine />
                      <InputDate 
                        wrapperClassName='w-25'
                        selected={fromDate}
                        onChange={date => setFromDate(date)}
                        dateFormat="dd/MM"
                        minDate={fromDates[0]}
                        maxDate={fromDates[1]}
                        placeholderText={t('services_details.calendar_from')}
                      />
                      <InputDate
                        wrapperClassName='w-25'
                        selected={toDate}
                        onChange={date => setToDate(date)}
                        dateFormat="dd/MM"
                        minDate={toDates[0]}
                        maxDate={toDates[1]}
                        placeholderText={t('services_details.calendar_to')}
                      />
                    </div>
                  }
                  <ServiceUsageBar data={usageData} type={resourceType} history={false}/>
                  {
                    mainChartData[0] &&
                    <ResponsiveContainer width={'99%'} height={500} className="py-4">
                      <BarChart
                        data={mainChartData}
                        margin={{
                          top: 5,
                          right: 0,
                          left: 5,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid vertical={false} />
                        <XAxis 
                          dataKey="name" 
                          axisLine={false} 
                          tickLine={false}    
                          angle={mainChartData.length > 2 ? -45 : 0}
                          textAnchor="end"
                          height={mainChartData.length > 2 ? 200 : 100}
                          interval={0} 
                          tick={{ fontSize: mainChartData.length > 2 ? 12 : "auto" }}
                        />
                        <YAxis 
                          tickCount={3} 
                          axisLine={false} 
                          tickLine={false} 
                          unit={Object.keys(mainChartData[0])[1] ? ` ${Object.keys(mainChartData[0])[1]}` : ''} 
                        />
                        <Tooltip labelFormatter={(value) => {
                          const dataPoint = mainChartData.find(point => point.name === value);
                          return dataPoint ? dataPoint.date : '';
                        }}/>
                        <Bar dataKey={Object.keys(mainChartData[0])[1]} fill="#B3D5FF" radius={[ 3, 3, 0, 0 ]} />
                      </BarChart>
                    </ResponsiveContainer>
                  }
                  <ServiceHistoryTable data={filteredData} type={resourceType} showChart={true} />
                </>
                :
                <Alert color="danger">{t('services.service_data_loading_error', { id: match?.params?.service_id })}</Alert>
            )
        }
      </Card>
    </>
  );
};

export default ServiceDetailsHistory;