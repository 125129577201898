import styled from 'styled-components';
import { Input } from 'reactstrap';

export const VerticalLine = styled.div`
  border-left: 1px solid #B0B0B0;
  height: 36px;
  width: 0%;
  margin: 0 1rem 0 1rem;
`;

export const InputDate = styled(Input)`
  border-radius: 6px;
  width: 180px;
  height: 36px;
  &:focus {
    border-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.text.blackFocus};
    outline: none;
    box-shadow: none;
  }
`;

export const DateContainer = styled.div`
  display: flex;
`;

export const OverDueDate = styled.span`
  color: #D20724;
`;
