import styled, { css } from 'styled-components';
import { Button, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { GoCloudDownload } from 'react-icons/go';
import { FaStar, FaCircle } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';
import { IoMdFolder } from 'react-icons/io';
import { ReactComponent as IconStar } from '../../../assets/svg/common/star.svg';

export const AppWrapper = styled.div`
  ${({ hideSidebar }) => hideSidebar ? ``
    : `
      display: grid; 
      grid-template-columns: 260px 1fr;
    `}
`;

export const MainContent = styled.main`
  min-height: calc(100vh - 8rem);
  padding: 35px;
`;

export const RoundedButton = styled(Button)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: ${(props) => props.fontSize};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 50% !important;
`;

export const StyledHr = styled.hr`
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
`;

export const RoundedImg = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 50%;
`;

export const Container = styled.div`
  margin: 0 auto;
`;

export const BasicButton = styled.button`
  border: none;
  background: transparent;
  outline: none !important;
  color: ${({ theme, color }) => theme.colors.button[color]};
  font-size: ${props => props.theme.fontSizes.sm};
  ${props => props.removePadding && css`
    padding: 0;
  `};
`;

export const RoundedInput = styled(Input)(
  ({ theme, error=false, icon, radius, text }) => `
    border-color: ${error ? theme.colors.error : theme.colors.text.lessDarkGrey};
    border-radius: ${radius};
    background-color: ${theme.colors.white} !important;
    padding: 10px 24px;
    color: ${theme.colors.text.greyHover};
    ${text && css`
      font-size: ${theme.fontSizes.md};
      line-height: 20.83px;
    `};
    &:focus {
      border-color: ${theme.colors.secondary};
      color: ${theme.colors.text.blackFocus};
      outline: none;
      box-shadow: none;
    }
    ${icon && css`
      background-image: url(${icon});
      background-repeat: no-repeat;
      background-position: 15px center;
      background-size: 5px contain;
      border-radius: 6px;
      padding-left: 45px;
    `};
  `
);

export const StyledLink = styled(Link)`
  color: inherit !important;
  text-decoration: ${({ $unstyled }) => $unstyled ? 'none' : 'underline'}!important;
`;

StyledLink.defaultProps = {
  $unstyled: false,
};

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${( props ) => props.height ? `${props.height}px` : "auto" };
`;

// Icons
export const FolderIcon = styled(IoMdFolder)`
  font-size: ${(props) => props.fontSize};
  color: #0FA1FF;
`;

export const FolderProgressIcon = styled(FaCircle)`
  font-size: ${(props) => props.fontSize};
  color: orange;
  margin-left: 10px;
  margin-bottom: 4px;
`;

export const DownloadIcon = styled(GoCloudDownload)`
  font-size: ${(props) => props.fontSize};
`;

export const StarIcon = styled(IconStar)`
  width: 30px;
  height: 30px;
  color: ${(props) => props.active ? props.theme.colors.link : props.theme.colors.text.grey1_5 };
  &:hover {
    color: ${(props) => props.theme.colors.linkHover};
  }
`;

export const FullStarIcon = styled(FaStar)`
  width: 30px;
  height: 30px;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.theme.colors.link };
`;

export const TriangleAlerIcon = styled(FiAlertTriangle)`
  font-size: ${(props) => props.fontSize};
`;
