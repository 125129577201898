import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BasicButton } from '../styles/styled-components/GlobalStyles';
import { formatDate } from '../utils/date';
import { Td, Institution } from '../styles/styled-components/Affiliations';
import axios from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';
import i18n from '../../main/utils/i18n';
import * as notify from '../../main/utils/notify';
import Loading from '../../main/components/loading';
import DefaultTooltip from './DefaultTooltip';

import WarningIcon from '../../assets/svg/common/circle-warning.svg';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { compose } from 'redux';
import backendTranslation from './../utils/backendTranslation';

const Affiliation = ({ item, loading, isModalOpen, setIsModalOpen, setSelectedAffiliation }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const renewAffilation = (type, id) => {
    const payload = {
      affiliation_type: type,
      affiliation_id: id
    };

    axios.post(`${__env.BPM_API_URL}affiliations/renew`, payload).then(response => {
      if (response?.data?.processName) {
        history.push(`/affiliations/refresh_affiliation_form/${response?.data?.processName}`);
      } 
      else {
        notify.error(
          '',
          i18n.t('affiliations.error')
        );
      } 
    });
  };

  const modalToggle = (id) => {
    if (!isModalOpen) setSelectedAffiliation(id);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <tr key={item.id}>
      <Td>
        <Institution>
          {backendTranslation(item?.affiliation_institution)}
        </Institution>
      </Td>
      <Td>{t([ `affiliation_types.${item.affiliation_type}`, item.affiliation_type ])}</Td>
      <Td>{formatDate(item.updated)}</Td>
      <Td>
        {
          new Date(item.expires) - Date.now() < 0 && 
            <>
              <DefaultTooltip 
                placement="top"
                target={`Tooltip-${item.id}`}
              >
                {t('affiliations_table.warning_tooltip')}
              </DefaultTooltip>
              <img src={WarningIcon} alt="Warning" className="mr-2 mb-1" id={`Tooltip-${item.id}`} />
            </>
        }
        {formatDate(item.expires)}
      </Td>
      <Td>{
        loading ? 
          <Loading /> :
          <>
            <BasicButton onClick={() => renewAffilation(item.affiliation_type, item.id)} color='darkBlue' removePadding className='mr-2'>
              {t('affiliations_table.extend')}
            </BasicButton>
            <BasicButton 
              onClick={() => modalToggle(item.id)} 
              color={new Date(item.expires) - Date.now() < 0 ? "disabled" : "danger"} 
              removePadding
              disabled={new Date(item.expires) - Date.now() < 0}
            >
              {t('affiliations_table.expire')}
            </BasicButton>
          </>
      }
      </Td>
    </tr>
  );
};

Affiliation.propTypes = {
  item: PropTypes.object,
  loading: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setSelectedAffiliation: PropTypes.func
};

export default compose(
  ErrorBoundary((props) => props.t('affiliation.error_boundary'))
)(Affiliation);
